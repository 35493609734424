import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;
  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;
    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }
    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;
      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }
      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;
        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }
      &.txt-color-red {
        color: #ff6969;
      }
    }
    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;
      > div {
        width: 50px;
      }
      .link-p {
        width: calc(100% - 50px);
        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;
          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }
    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }
  ${BreakPoints.large} {
  }
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0046 = (props: Props): JSX.Element => (
  <Wrapper>
    <ColumnH1 label="働き方改革における「産業医機能の強化」とは？詳しく解説" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2023.03.14</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        時間外労働の上限規制や年次有給休暇5日取得義務等、罰則を設けた法改正に注目が集まりがちの働き方改革ではありますが、働き方改革の中には産業医・産業保健機能の強化も重要な施策として挙げられています。今回はその具体的な内容について解説します。{' '}
      </p>
      <img src="/images/column/details/c0046.jpg" alt="キンクラコラム画像46" />
      <h1 id="toc1">産業医とは</h1>
      <p>
        まず、産業医の選任については1972年に労働基準法から分離独立した労働安全衛生法に根拠規定が置かれ、
        <span>
          業種と問わず、常時50人以上の労働者を使用する事業場は産業医の選任が義務付けられています。
        </span>
        なお、実効性を確保する意味で、2017年4月1日の改正により次の三者は産業医として選任することができなくなっています。
        <br />
        <br />
        ・法人企業の場合、法人の代表者
        <br />
        ・法人でない場合、事業を営む個人
        <br />
        ・事業の実施を統括管理する者
        <br />
        <br />
        上記の者は、いわば企業の経営に近い存在であり、労働者の健康確保より企業利益を優先してしまう可能性があり、産業医としての職務が適切に遂行されない恐れがあるため選任ができなくなりました。
      </p>

      <h1 id="toc2">産業保健機能の強化の目的とは</h1>
      <p>
        労働者の心身を蝕むものの代表的なものとして、「長時間労働」が挙げられます。長時間労働に起因してメンタルヘルス不調となってしまえば、健康な状態で労務の提供ができなくなるだけでなく、メンバーシップ型雇用を採用する多くの日本企業では、そのしわ寄せが他の従業員へもいくことが懸念されています。そのため、メンタルヘルス不調に陥る労働者を見逃さないため、
        <span>産業医による面接指導や健康相談等を確実に実施する</span>
        ために、働き方改革の一環として、産業保健機能の強化が盛り込まれたということです。
      </p>

      <h1 id="toc3">産業保健機能の強化の目的とは</h1>
      <p>
        産業医は労働者の健康確保を行うために医学の分野において専門的な立場から中立的に助言および指導を行います。そして、産業医自らも働き方の多様化や時代の進歩とともに、必要な医学に関する知識および能力の向上に努めることが求められています。
        <br />
        <br />
        また、産業医の身分の安定性を担保するため、
        <span>
          産業医が辞任または産業医を解任した場合、遅滞なく(概ね1か月以内)その旨と理由を衛生委員会または安全衛生委員会(以下、衛生委員会等)に報告しなければなりません。
        </span>
        <br />
        <br />
        主治医と異なり、産業医は医師であるだけでなく、その事業場の職場環境も踏まえた中立的な判断、助言できるため、多くの場合、主治医よりも健康状態と職場環境を総合的に勘案した的確な助言が可能であると考えられます。
      </p>

      {/** ここに9パターンの広告設定する */}
      {props.no === 0 && <Introduction004 />}
      {props.no === 1 && <Introduction005 />}
      {props.no === 2 && <Introduction006 />}
      {props.no === 3 && <Introduction007 />}
      {props.no === 4 && <Introduction008 />}
      {props.no === 5 && <Introduction009 />}
      {props.no === 6 && <Introduction010 />}
      {props.no === 7 && <Introduction011 />}
      {props.no === 8 && <Introduction012 />}

      <h1 id="toc4">産業医への情報提供の充実・強化</h1>
      <p>
        産業医は原則として<span>毎月1回以上、職場内を巡視</span>
        し、作業方法や衛生状態に有害の恐れがある場合、直ちに労働者の健康障害を防止するための必要な措置を講じなければならないとされています。しかし、
        <span>
          毎月1回以上事業者から衛生管理者による巡視の結果等が提供されること、事業者の同意があることという要件を満たす場合に限り、毎月１回以上の巡視が「少なくとも2か月に1回」でも可能
        </span>
        となります。
        <br />
        <br />
        しかし、巡視の頻度を減らすことで実効性が確保できなければ本末転倒ですので、産業医が巡視する際に、例えばアンケート調査等を通じて労働者から必要な情報を収集する方法を認める等の対応が求められます。併せて、情報収集の対象となった労働者の人事上の評価や処遇において、不利益が生じないよう配慮しなければなりません。
      </p>

      <h1 id="toc5">産業医の活動と衛生委員会等との連携強化</h1>
      <p>
        産業医の勧告を受けた場合、遅滞なく勧告の内容および勧告を踏まえて講じた装置の内容を衛生委員会等に報告しなければなりません。これは勧告を受けただけでその後の行動に結びついていないことが多く見受けられていたためです。特に、ある程度の規模の組織となれば実効性を確保するには、組織的な対応が求められることも少なくありません。
        <br />
        <br />
        他方、産業医が衛生委員会等に対して労働者の健康を確保する観点から、必要な範囲内で調査審議を求めることが可能となります。
      </p>

      <h1 id="toc6">記録の保存</h1>
      <p>
        衛生委員会等の開催の都度、当該委員会等で審議された内容を議事録として
        <span>3年間は保存</span>
        しなければなりません。その中には産業医の意見を踏まえた講じた措置の内容も含まれます。
      </p>

      <h1 id="toc7">長時間労働者に対する面接指導</h1>
      <p>
        多くの事業所では特定の労働者が長時間労働に陥っており、そのような働き方から脱却できないという問題が少なくありません。そのような場合、労働者本人に自覚はなくても医学的には既に心身に支障をきたしているというケースがあります。そこで、組織としては、タイムカードなど客観的な手法により適切に労働時間を管理することが絶対必要条件となります。もちろん、この中には裁量労働制で働く労働者や管理監督者も含まれます。その理由として、割増賃金上の労働時間の考え方と健康確保上の労働時間の考え方は目的が異なるためです。
        <br />
        <br />
        なお、
        <span>
          時間外および休日労働時間数が1か月80時間を超え、かつ、疲労の蓄積が認められる者は面接指導の対象
        </span>
        となります。
      </p>

      <h1 id="toc8">最後に</h1>
      <p>
        企業として利益を上げていくことは必要なことであり、それは永続的に変わることはないでしょう。そのために、労働者には健康な状態で働いてもらう必要があります。特に外見からは容易に判別し難い精神疾患は、医学の知識がなければ把握するまでに時間を要することも少なくありません。よって、これまで以上に産業医との連携を強化していく必要性が高いと考えます。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0046
